<script>
import BackstockHistory from "./components/BackstockHistory";
import VxDialogView from "@/components/vx/VxDialogView";
import CategorySelect from "./components/CategorySelect";
import LocationSelect from "./components/LocationSelect";
import { backstockRules, backstockMethods } from "./mixins/backstock";

import { format, parseISO } from "date-fns";
import { findIndex, omit, get } from "lodash";
import {
  UPDATE_BACKSTOCK,
  BACKSTOCK_HISTORY,
  BACKSTOCK_WITHOUT_HISTORY,
} from "./graphql";
import { mapMutations } from "vuex";
import gql from "graphql-tag";

export default {
  name: "BackstockEditBinDialog",
  components: {
    CategorySelect,
    LocationSelect,
    VxDialogView,
    BackstockHistory,
  },
  mixins: [backstockRules, backstockMethods],
  inheritAttrs: false,
  props: {
    backstockId: {
      type: String,
      default: "",
    },
    storeId: {
      type: String,
      default: "",
    },
    binId: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      invDateMenu: false,
      formattedToday: format(new Date(), "yyyy-MM-dd"),
      updateBackstockLoading: false,
      moveBackstockLoading: false,
      historyLoading: false,
      backstockLoading: false,
      nextLocation: null,
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    dialogTitle() {
      const binName = this.backstockWithNOHistory?.bin?.name;
      return !!this.backstockWithNOHistory
        ? this.$t("backstock.app.editBinDialog.title", { binName })
        : this.$t("backstock.app.editBinDialog.loadingText");
    },
    formattedInvDate() {
      const date = get(this.backstockWithNOHistory, "invDate");
      return (date && format(parseISO(date), "MMMM do, yyyy")) || "";
    },
    backstockQueryVariables() {
      return {
        storeId: this.storeId,
      };
    },
  },
  apollo: {
    backstockWithNOHistory: {
      watchLoading(isLoading) {
        this.backstockLoading = !!isLoading;
      },
      query: BACKSTOCK_WITHOUT_HISTORY,
      variables() {
        return { ...this.backstockQueryVariables, id: this.backstockId };
      },
      update({ backstock }) {
        this.nextLocation = backstock.location.nextLocation;

        return backstock;
      },
    },
    backstockWithHistory() {
      return {
        watchLoading(isLoading) {
          this.historyLoading = isLoading;
        },
        query: BACKSTOCK_HISTORY,
        context: { skipBatching: true },
        variables() {
          return {
            ...this.backstockQueryVariables,
            id: this.backstockId,
          };
        },
        update({ backstock }) {
          return backstock;
        },
      };
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    async moveBackstock(backstock, newLocation) {
      this.moveBackstockLoading = true;
      const oldLocation = backstock.location;
      try {
        const {
          data: {
            updateBackstock: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_BACKSTOCK,
          variables: {
            input: {
              id: backstock.id,
              locationId: newLocation.id,
            },
          },
          // TODO - refetchQueries: [] ??
          update: (
            store,
            {
              data: {
                updateBackstock: { backstock },
              },
            }
          ) => {
            const variables = { ...this.backstockQueryVariables };

            // If the new location is terminal
            // - remove the backstock
            // - add the bin back in
            if (backstock.location.terminal) {
              // -- Remove backstock
              // define the backstock query
              const backstockQuery = gql`
                query ($storeId: ID!) {
                  backstocks(storeId: $storeId) {
                    id
                  }
                }
              `;
              // read from the cache
              const { backstocks = [] } = store.readQuery({
                query: backstockQuery,
                variables,
              });
              // try to find the backstock in the list
              const backstockIndex = findIndex(backstocks, {
                id: backstock.id,
              });
              // if found, remove it from the list
              if (backstockIndex > -1) {
                const beforeLength = backstocks.length;
                backstocks.splice(backstockIndex, 1);
                // write back to the cache, if `backstocks` changed
                if (beforeLength !== backstocks.length) {
                  store.writeQuery({
                    query: backstockQuery,
                    variables,
                    data: { backstocks: [...backstocks] },
                  });
                }
              }
              // -- Add the bin
              // define the bins query
              const binQuery = gql`
                query ($storeId: ID!) {
                  bins(storeId: $storeId, available: true) {
                    id
                  }
                }
              `;
              // read from the cache
              const { bins = [] } = store.readQuery({
                query: binQuery,
                variables,
              });
              // write the bins back to the cache
              store.writeQuery({
                query: binQuery,
                variables,
                data: { bins: [...bins, backstock.bin] },
              });
            }

            // -- Update the old location count
            // define the fragment
            const locationFragment = gql`
              fragment UpdateCount on Location {
                id
                binCount
              }
            `;
            // read the fragment
            const cachedLocation = store.readFragment({
              fragment: locationFragment,
              id: `Location:${oldLocation.id}`,
            });
            // decrement the binCount
            if (cachedLocation) {
              store.writeFragment({
                fragment: locationFragment,
                id: `Location:${oldLocation.id}`,
                data: {
                  ...cachedLocation,
                  binCount: cachedLocation.binCount - 1,
                },
              });
            }
          },
        });

        // Rethrow errors
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }
        // If we made it this far, we were successful!
        //this.  = false;

        this.moveBackstockLoading = false;
        this.$router.go(-1);
        this.showSnackbar({
          text: this.$t(
            "backstock.app.editBinDialog.moveBackstockSuccessMessage"
          ),
        });
      } catch (error) {
        this.showSnackbar({
          text: this.$t(
            "backstock.app.editBinDialog.moveBackstockErrorMessage",
            {
              error,
            }
          ),
        });

        this.moveBackstockLoading = false;
      }
      this.$set(backstock, "loading", false);
    },
    async saveBackstock() {
      await this.updateBackstock();
      this.$router.go(-1);
    },
    async updateBackstock() {
      this.updateBackstockLoading = true;
      const old = { ...this.backstockWithNOHistory };
      try {
        const {
          data: {
            updateBackstock: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_BACKSTOCK,
          variables: {
            input: {
              id: this.backstockWithNOHistory.id,
              ...omit(
                this.backstockPayload(this.backstockWithNOHistory),
                "binId"
              ),
            },
          },
          update: (
            store,
            {
              data: {
                updateBackstock: { backstock },
              },
            }
          ) => {
            if (backstock === undefined) {
              return false;
            }
            // If the backstock's new location is terminal, remove it from
            // the cache and add the bin back
            if (backstock.location.terminal) {
              // -- Remove the backstock
              // define the backstock query
              const backstockQuery = gql`
                query ($storeId: ID!) {
                  backstocks(storeId: $storeId) {
                    id
                  }
                }
              `;
              // read from the cache
              const { backstocks = [] } = store.readQuery({
                query: backstockQuery,
                variables: { storeId: this.$route.params.storeId },
              });
              // remove the backstock from the list
              const backstockIndex = findIndex(backstocks, {
                id: backstock.id,
              });
              if (backstockIndex > -1) {
                backstocks.splice(backstockIndex, 1);
                store.writeQuery({
                  query: backstockQuery,
                  variables: { storeId: this.$route.params.storeId },
                  data: { backstocks: [...backstocks] },
                });
              }

              // -- Add the bin
              // define the bin query
              const binQuery = gql`
                query ($storeId: ID!) {
                  bins(storeId: $storeId, available: true) {
                    id
                  }
                }
              `;
              // read from the cahce
              const { bins = [] } = store.readQuery({
                query: binQuery,
                variables: { storeId: this.$route.params.storeId },
              });
              // write the bin back to the cache
              store.writeQuery({
                query: binQuery,
                variables: { storeId: this.$route.params.storeId },
                data: { bins: [...bins, backstock.bin] },
              });
            }

            // Update the old category.backstocksCount count if category changed
            if (old.category.id !== backstock.category.id) {
              // define fragment query
              const categoryFragment = gql`
                fragment UpdateCategoryCount on Category {
                  id
                  backstocksCount
                }
              `;
              const categoryCacheId = `Category:${old.category.id}`;
              // read from the cache
              const category = store.readFragment({
                fragment: categoryFragment,
                id: categoryCacheId,
              });
              if (category) {
                store.writeFragment({
                  fragment: categoryFragment,
                  id: categoryCacheId,
                  data: {
                    ...category,
                    backstocksCount: category.backstocksCount - 1,
                  },
                });
              }
            }

            // Update the old category.backstocksCount count if category changed
            if (old.location.id !== backstock.location.id) {
              // define fragment query
              const locationFragment = gql`
                fragment UpdateLocationCount on Category {
                  id
                  binCount
                }
              `;
              const locationCacheId = `Location:${old.location.id}`;
              const location = store.readFragment({
                fragment: locationFragment,
                id: locationCacheId,
              });
              if (location) {
                store.writeFragment({
                  fragment: locationFragment,
                  id: locationCacheId,
                  data: {
                    ...location,
                    binCount: location.binCount - 1,
                  },
                });
              }
            }
          },
        });
        // rethrow errors
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }
        // if we've made it this far, we were successful
        this.showSnackbar({
          text: this.$t("backstock.app.editBinDialog.successMessage"),
        });

        this.updateBackstockLoading = false;
      } catch (error) {
        this.showSnackbar({
          text: this.$t("backstock.app.editBinDialog.errorMessage", {
            error,
          }),
        });
      }
      this.updateBackstockLoading = false;
    },
  },
};
</script>

<template>
  <VxDialogView
    :title="dialogTitle"
    :retain-focus="false"
    :v-size="'large'"
    :h-size="'small'"
  >
    <template #actions>
      <v-btn
        :color="isSmallScreen ? null : 'primary'"
        :text="true"
        :loading="updateBackstockLoading"
        :disabled="!formValid"
        @click="saveBackstock"
      >
        {{ $t("backstock.app.editBinDialog.okButtonText") }}
      </v-btn>
    </template>
    <template>
      <div v-if="!!backstockLoading && !backstockWithNOHistory">
        <!-- TODO: add progress spinner instead of text -->
        {{ $t("backstock.app.editBinDialog.loadingText") }}
      </div>
      <v-form v-if="!!backstockWithNOHistory" ref="form" v-model="formValid">
        <CategorySelect
          v-model="backstockWithNOHistory.category"
          :label="$t('backstock.app.editBinDialog.categoryLabel')"
          @input="updateBackstock"
        />
        <v-menu
          v-model="invDateMenu"
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="formattedInvDate"
              :label="$t('backstock.app.editBinDialog.inventoryDateLabel')"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="backstockWithNOHistory.invDate"
            :max="formattedToday"
            :rules="rules.invDate"
            required
            @change="
              invDateMenu = false;
              updateBackstock();
            "
          />
        </v-menu>
        <v-text-field
          v-model="backstockWithNOHistory.notes"
          :label="$t('backstock.app.editBinDialog.notesLabel')"
          @blur="updateBackstock"
        />
        <LocationSelect
          v-model="backstockWithNOHistory.location"
          :label="$t('backstock.app.editBinDialog.locationLabel')"
          @input="updateBackstock"
        />
        <v-btn
          v-if="nextLocation"
          :loading="moveBackstockLoading"
          color="secondary"
          @click.stop="moveBackstock(backstockWithNOHistory, nextLocation)"
        >
          <v-icon class="pr-2" v-text="'$vuetify.icons.moveBin'" />
          {{
            $t("backstock.app.editBinDialog.moveToLocationButtonText", {
              locationName: nextLocation.name,
            })
          }}
        </v-btn>

        <v-checkbox
          v-model="backstockWithNOHistory.archived"
          :label="$t('backstock.app.editBinDialog.archivedLabel')"
        />
      </v-form>

      <div v-if="historyLoading && !backstockWithHistory">
        {{ $t("backstock.app.editBinDialog.historyLoadingText") }}
      </div>
      <v-divider v-if="backstockWithHistory"></v-divider>
      <BackstockHistory
        v-if="backstockWithHistory"
        :backstock-histories="
          backstockWithHistory ? backstockWithHistory.backstockHistories : null
        "
      />
    </template>
  </VxDialogView>
</template>
