<script>
import { orderBy } from "lodash";

import { format, isBefore, subDays, isToday, parseISO } from "date-fns";
import EmployeeAvatarQuery from "@/components/employees/EmployeeAvatarQuery";

/**
 * - Dates are always in the past.
 * - Time of day IS important.
 * - Precision is not all that important  (i.e. the difference in 350 days ago and 340 days ago)
 *
 * If date is today, show hours
 * If date is more than 1 year ago, show MMM YYYY
 * Otherwise, show MMM D
 * TODO: change to 11 months instead of 365 days.  (or even 6 months)
 *   - On January 19, 2020:
 *     Good:
 *       - Jan 19  (January 19, 2020)
 *       - Dec 20  (December 20, 2019)
 *       - Feb 19  (February 19, 2019)
 *       - Jan 2019  (January 30, 2019)
 *     Bad:
 *       - January 19  (January 19, 2020)
 *       - Jan 2020    (January 19, 2020)
 *       - Dec 2019    (December 20, 2019)
 *       - Jan 30      (January 30, 2019)
 *       - Jan 19      (January 30, 2019) - YY is too ambiguous
 */
function parseInvDate(invDate) {
  const invDateParsed = parseISO(invDate);
  let strFormat = "MMM d";
  if (isToday(invDateParsed)) {
    strFormat = "h:mm a";
  }
  if (isBefore(invDateParsed, subDays(new Date(), 365))) {
    strFormat = "MMM yyyy";
  }

  return invDate ? format(invDateParsed, strFormat) : "";
}

export default {
  name: "BackstockHistory",
  components: { EmployeeAvatarQuery },
  props: {
    backstockHistories: { type: Array, default: () => [] },
  },
  computed: {
    backstockHistoriesForDisplay() {
      const backstockHistoriesOrdered = orderBy(
        this.backstockHistories,
        ["updatedAt"],
        ["desc"]
      );

      // Determine change for each history Item, comparing to previous history item
      for (
        let historyItemIndex = backstockHistoriesOrdered.length - 1;
        historyItemIndex >= 0;
        historyItemIndex--
      ) {
        backstockHistoriesOrdered[historyItemIndex].displayUpdateDate =
          parseInvDate(backstockHistoriesOrdered[historyItemIndex].updatedAt);
        backstockHistoriesOrdered[historyItemIndex].displayInvDate = format(
          parseISO(backstockHistoriesOrdered[historyItemIndex].invDate),
          "MM/dd/yy"
        );
        backstockHistoriesOrdered[historyItemIndex].updatesToDisplay = [];

        if (historyItemIndex === backstockHistoriesOrdered.length - 1) {
          backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
            "Bin created."
          );

          if (backstockHistoriesOrdered[historyItemIndex].category) {
            backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
              `Category: ${backstockHistoriesOrdered[historyItemIndex].category.name}.`
            );
          }

          if (backstockHistoriesOrdered[historyItemIndex].location) {
            backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
              `Location: ${backstockHistoriesOrdered[historyItemIndex].location.name}.`
            );
          }

          backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
            `Inventory date: ${backstockHistoriesOrdered[historyItemIndex].displayInvDate}.`
          );

          backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
            `Notes: '${
              backstockHistoriesOrdered[historyItemIndex].notes
                ? backstockHistoriesOrdered[historyItemIndex].notes
                : ""
            }'.`
          );
        } else {
          const currentItem = backstockHistoriesOrdered[historyItemIndex];
          const previousItem = backstockHistoriesOrdered[historyItemIndex + 1];

          if (currentItem.category.id !== previousItem.category.id) {
            backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
              `Category changed to ${currentItem.category.name}. `
            );
          }

          if (currentItem.location.id !== previousItem.location.id) {
            backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
              `Location changed to ${currentItem.location.name}. `
            );
          }

          if (currentItem.notes !== previousItem.notes) {
            backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
              `Notes changed to '${
                !!currentItem.notes ? currentItem.notes : ""
              }'. `
            );
          }

          if (currentItem.invDate !== previousItem.invDate) {
            backstockHistoriesOrdered[historyItemIndex].updatesToDisplay.push(
              `Inventory date changed to ${currentItem.displayInvDate}. `
            );
          }
        }
      }

      return backstockHistoriesOrdered;
    },
  },
};
</script>

<template>
  <div>
    <div class="text-h5 mt-3 mb-2">HISTORY</div>
    <div
      v-if="
        !backstockHistoriesForDisplay || !backstockHistoriesForDisplay.length
      "
    >
      No history data
    </div>
    <v-list
      v-if="backstockHistoriesForDisplay && backstockHistoriesForDisplay.length"
    >
      <v-list-item
        v-for="backstockHistoryItem in backstockHistoriesForDisplay"
        :key="backstockHistoryItem.id"
        class="pl-0 pr-0 mt-3 mb-3"
      >
        <div class="mr-auto align-self-middle d-flex">
          <div class="align-self-middle flex-shrink-0 mr-2 update-date">
            {{ backstockHistoryItem.displayUpdateDate }}
          </div>
          <div class="align-self-middle">
            <div
              v-for="update in backstockHistoryItem.updatesToDisplay"
              :key="update"
            >
              {{ update }}
            </div>
          </div>
        </div>
        <div class="ml-2 align-self-middle">
          <EmployeeAvatarQuery
            :employee-id="backstockHistoryItem.employeeId"
            class="buy-row__td--sorter__avatar__img--selected"
          />
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<style scoped>
.update-date {
  min-width: 70px;
}
</style>
